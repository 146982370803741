// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
const Sentry = require('@sentry/browser');


/**
 * Initialize
 */

if (process.env.ENV !== 'development') {
	Sentry.init({
		dsn: process.env.SENTRY_JS_DSN,
		release: `smarttrack-web@${process.env.PACKAGE_VERSION}`,
		environment: process.env.ENV
	});
}
